// // // Install slick twice. npm install slick-carousel --save THEN npm install slick-carousel
// // // import "slick-carousel/slick/slick.css";
// // // import "slick-carousel/slick/slick-theme.css";

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Photos.css';

const PhotoGallery = () => {
  const images = [
    { url: './images/jatrpic1.jpeg', photographer: 'Photo. JATR' },
    { url: './images/jatrpic2.jpeg', photographer: 'Photo. JATR' },
    { url: './images/jatrpic3.jpeg', photographer: 'Photo. Andres Sotelo' },
    { url: './images/jatrpic4.jpeg', photographer: 'Photo. Carine Renaud' },
    { url: './images/jatrpic5.jpeg', photographer: 'Photo. JATR' },
    { url: './images/jatrpic6.jpeg', photographer: 'Photo. JATR' },
    { url: './images/jatrpic7.jpeg', photographer: 'Photo. David Brendan Hall' },
    { url: './images/jatrpic8.jpeg', photographer: 'Photo. JATR' },
    { url: './images/jatrpic9.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic10.jpeg', photographer: 'Photo. Diego Santillán' },
    { url: './images/jatrpic11.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic12.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic13.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic14.jpeg', photographer: 'Photo. David Brendan Hall' },
    { url: './images/jatrpic15.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic16.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic17.jpeg', photographer: 'Photo. Jaime Fernandez' },
    { url: './images/jatrpic18.jpeg', photographer: 'Photo. Iván Castillo' },
    { url: './images/jatrpic19.jpeg', photographer: 'Photo. @___lungs' },
    { url: './images/jatrpic20.jpeg', photographer: 'Photo. Jaime Fernandez' },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust the number of images to show
    slidesToScroll: 5, // Adjust the number of images to scroll
    centerMode: true,
    focusOnSelect: true,
    swipeToSlide: true,
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Adjust the number of images to show on smaller screens
          slidesToScroll: 3, // Adjust the number of images to scroll on smaller screens
        },
      },
    ],
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="photo-gallery">
      <div className="main-image">
        <img src={images[activeIndex].url} alt={`Image ${activeIndex}`} />
        <p>{images[activeIndex].photographer}</p>
      </div>
      <div className="thumbnail-carousel">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} onClick={() => handleThumbnailClick(index)}>
              <img src={image.url} alt={`Thumbnail ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PhotoGallery;

































// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import './Photos.css';

// const PhotoGallery = () => {
//   const images = [
//     './images/jatrpic1.jpeg',
//     './images/jatrpic2.jpeg',
//     './images/jatrpic3.jpeg',
//     './images/jatrpic4.jpeg',
//     './images/jatrpic5.jpeg',
//     './images/jatrpic6.jpeg',
//     './images/jatrpic7.jpeg',
//     './images/jatrpic8.jpeg',
//     './images/jatrpic9.jpeg',
//     './images/jatrpic10.jpeg',
//     './images/jatrpic11.jpeg',
//     './images/jatrpic12.jpeg',
//     './images/jatrpic13.jpeg',
//     './images/jatrpic14.jpeg',
//     './images/jatrpic15.jpeg',
//     './images/jatrpic16.jpeg',
//     './images/jatrpic17.jpeg',
//     './images/jatrpic18.jpeg',
//     './images/jatrpic19.jpeg',
//     './images/jatrpic20.jpeg',
//   ];

//   const [activeIndex, setActiveIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, [images]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 5,
//     centerMode: true,
//     focusOnSelect: true,
//     swipeToSlide: true, 
//     afterChange: (index) => setActiveIndex(index),
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3, // Adjust for smaller screens if needed
//         },
//       },
//     ],
//   };

//   const handleThumbnailClick = (index) => {
//     setActiveIndex(index);
//   };

//   return (
//     <div className="photo-gallery">
//       <div className="main-image">
//         <img src={images[activeIndex]} alt={`Image ${activeIndex}`} />
//       </div>
//       <div className="thumbnail-carousel">
//         <Slider {...settings}>
//           {images.map((image, index) => (
//             <div key={index} onClick={() => handleThumbnailClick(index)}>
//               <img src={image} alt={`Thumbnail ${index}`} />
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default PhotoGallery;







// Photographers:
// Photo. JATR
// Photo. JATR
// Photo. Andres Sotelo
// Photo. Carine Renaud
// Photo. JATR
// Photo. JATR
// Photo. David Brendan Hall
// Photo. JATR
// Photo. Jaime Fernandez
// Photo. Diego Santillán 
// Photo. Jaime Fernandez
// Photo. Jaime Fernandez
// Photo. Jaime Fernandez
// Photo. David Brendan Hall
// Photo. Jaime Fernandez
// Photo. Jaime Fernandez
// Photo. Jaime Fernandez
// Photo. Iván Castillo
// Photo. @___lungs
// Photo. Jaime Fernandez













