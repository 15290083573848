//Footer.js

import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
      <p>Copyright © Jack and The Ripper. All rights reserved. Official Site</p>
    </footer>
  );
}

export default Footer;