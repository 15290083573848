// Home.js


import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './Home.css';

function Home() {
  const youtubeUrl = 'https://www.youtube.com/embed/-zvKpjHNCLI';

  const [playerWidth, setPlayerWidth] = useState('560px');

  useEffect(() => {
    const updatePlayerWidth = () => {
      const viewportWidth = window.innerWidth;

      // Adjust the player width dynamically based on screen size
      if (viewportWidth <= 768) {
        setPlayerWidth(`${viewportWidth * 0.9}px`); // 90% of the screen width for mobile
      } else {
        setPlayerWidth('560px'); // Default width for larger screens
      }
    };

    // Set initial player width
    updatePlayerWidth();

    // Add resize event listener
    window.addEventListener('resize', updatePlayerWidth);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', updatePlayerWidth);
  }, []);

  return (
    <section id="home">
      <div className="video-container">
        <ReactPlayer
          url={youtubeUrl}
          controls
          width={playerWidth}
          height="315px" // Maintain aspect ratio by keeping height fixed
        />
      </div>
      <h2>New Single "Exile" Out Now!</h2>
      <p>Music Video Generated with AI</p>
    </section>
  );
}

export default Home;




// OPTION A

// import React, { useState, useEffect } from 'react';
// import ReactPlayer from 'react-player';
// import './Home.css';

// function Home() {
//   const youtubeUrl = 'https://www.youtube.com/embed/-zvKpjHNCLI';

//   const [playerSize, setPlayerSize] = useState({ width: '560px', height: '315px' });

//   useEffect(() => {
//     const updatePlayerSize = () => {
//       if (window.innerWidth <= 768) {
//         setPlayerSize({ width: '100%', height: '300px' }); // Adjust height as needed for mobile
//       } else {
//         setPlayerSize({ width: '560px', height: '315px' });
//       }
//     };

//     // Initial check
//     updatePlayerSize();

//     // Add resize event listener
//     window.addEventListener('resize', updatePlayerSize);

//     // Clean up event listener
//     return () => window.removeEventListener('resize', updatePlayerSize);
//   }, []);

//   return (
//     <section id="home">
//       <div className="video-container">
//         <ReactPlayer
//           url={youtubeUrl}
//           controls
//           width={playerSize.width}
//           height={playerSize.height}
//         />
//       </div>
//       <h2>New Single "Exile" Out Now!</h2>
//       <p>Music Video Generated with AI</p>
//     </section>
//   );
// }

// export default Home;




// ORIGINAL


// import React from 'react';
// import './Home.css';
// import ReactPlayer from 'react-player';

// function Home() {
//   const youtubeUrl = 'https://www.youtube.com/embed/-zvKpjHNCLI';

//   return (
//     <section id="home">
//       <div className="video-container">
//         <ReactPlayer url={youtubeUrl} width="560px" height="315px" controls />
//       </div>
//       <h2>New Single "Exile" Out Now!</h2>
//       <p>Music Video Generated with AI</p>
//     </section>
//   );
// }

// export default Home;
