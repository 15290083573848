import React, { useState } from 'react';
import './Bio.css'; // Import your CSS file

const Bio = () => {
  const [isEnglish, setIsEnglish] = useState(true); // Default to English bio

  const englishBio = (
    <div>
  <p>
    Jack and The Ripper is an electronic rock duo conformed of twin brothers: Daniel and David Barbedillo. JATR merges music, video, virtual reality, augmented reality, digital art, 3D modeling, video games, deep learning, and animation with a DIY ethos in all of their work.
  </p>
  <p>
    On April 14, 2014, JATR released its first official album "Vol. 2". The LP received positive reviews from local media including the blog 'Me Hace Ruido' listing "Vol. 2" as one of the best album releases of 2014.
  </p>
  <p>
    The following year, the duo played Festival Marvin (05-16-15). A month before the show, JATR recorded and produced an Os Mutantes cover "The Dream is Gone" which sparked a positive review from Sergio Dias (Os Mutantes). Later this same year, JATR performed at the Franz Mayer Museum (09-27-15).
  </p>
  <p>
    On April 19, 2016, Jack and The Ripper released their first Virtual Reality Music App Single "JATR VR" and headlined the first International Virtual Reality Festival in Mexico (08-26-16) where they debuted their first Augmented Reality Live Show.
  </p>
  <p>
    March 15, 2017, Jack and The Ripper headlined an official SXSW Showcase curated by Desert Daze Festival, Good Vibrations, and Foro Indie Rocks. On July 3, 17, they released their second Virtual Reality App Single “JATRVR2” and on October 31, 17, the duo released their third Virtual Reality Single “Waves”.
  </p>
  <p>
    On August 3, 2018, JATR released its fourth Virtual Reality Experience “Homemade Lobotomy Live @ Milliways” and on October 12, 2018, the duo released its second LP “Waking Dream". 'NOFM-Radio' listed 'Homemade Lobotomy' as one of the best musical releases of 2018.
  </p>
  <p>
    On November 6, 2019, Jack and The Ripper released their first Online 2D Videogame Music Single "Can't Forgive a Ghost" from their second LP "Waking Dream".
  </p>
  <p>
    On March 20, 2020, JATR released its live performance "Jack and The Ripper Live at Glitchville Under Quarantine" and on June 27 the duo released "Pointless" the second level of their online 2D Videogame. On August 26, JATR released the single "Circles" from their second album as a 3D Hover Skate Videogame and on August 28 they released "Chasing Your Tail" another single from their second LP as a 3D Bowling Videogame.
  </p>
  <p>
    From August 30 to September 7, Jack and The Ripper showcased their Virtual Reality Experiences at Burning Man Project VR 2020. On December 4, 2020, JATR released a video for their single "Blood on the Wind" where Jack and The Ripper come to life as avatars on a stage full of flashing and unreal visuals intermingling glitch art, static and digital psychedelia.
  </p>
  <p>
    On April 20, 2021, the duo released two AI-Generated cover versions by Frank Sinatra and Ella Fitzgerald of JATR's "Homemade Lobotomy" from their second LP "Waking Dream". From August 29 through September 7, 2021, JATR premiered "Cosmonaut" a new Virtual Reality Experience at Burning Man Project Virtual Edition 2021.
  </p>
  <p>
    On October 25, 2021, JATR released a new 3D videogame titled JATR-3D "Zombies from Outer Space".
  </p>
  <p>
    On January 20, 2023, the duo released a new video generated with artificial intelligence for their single titled "Exile".
  </p>
  <p>
    A very special connection between Jack and The Ripper can be wildly appreciated during their live performances where cathartic and frenetic shows are delivered by a pair of clones bashing guitar glitch solos and lasers through perplexing beautiful and vicious electronic atmospheres.
  </p>
</div>

  );

  const spanishBio = (
    <div>
  <p>
    Jack and The Ripper es un dúo de rock electrónico conformado los hermanos gemelos: Daniel y David Barbedillo. JATR fusiona música rock, electrónica, programación, realidad virtual / aumentada, videojuegos y arte digital con un ethos DIY dentro de todas sus obras.
  </p>
  <p>
    El primer álbum de Jack and The Ripper "Vol. 2" fue lanzado el 14 de Abril del 2014 destacando como Mejores discos del año (núm. 3 -Listas - Me Hace Ruido) Y se presentaron en el festival Marvin 2015.
  </p>
  <p>
    El 19 de Abril de 2016, Jack y The Ripper lanzó su primera aplicación de música en realidad virtual "JATR VR" y el 26 de Agosto de ese mismo año, JATR fue invitado a encabezar el primer Festival Internacional de Realidad Virtual en México (VR FEST MX), donde debutaron su show creando y utilizando por primera vez una aplicación DIY de Realidad Aumentada en vivo junto con una conferencia sobre su última creación musical.
  </p>
  <p>
    El 15 de marzo de 2017, Jack and The Ripper fue invitado a encabezar un showcase oficial de SXSW curado por Desert Daze Festival, Good Vibrations y Foro Indie Rocks.
  </p>
  <p>
    3 de Agosto 2018, JATR lanzó su cuarta experiencia en Realidad Virtual “Homemade Lobotomy Live @ Milliways” y en Octubre 12, 2018 el dúo libera su segundo álbum “Waking Dream” seguido por su primer videojuego Plataforma 2D (Noviembre 6 2019) versión mobile y desktop acompañando temas del álbum Waking dream.
  </p>
  <p>
    Durante los primeros meses de la pandemia (Marzo 20, 2020) JATR libera un concierto virtual en redes titulado “Jack and The Ripper Live at Glitchville Under Quarantine” y el 27 de junio el dúo libera “Pointless” el segundo nivel de su video juego 2D online.
  </p>
  <p>
    Del 30 de agosto al 7 de septiembre, Jack y The Ripper mostraron todas sus experiencias de realidad virtual en Burning Man Project VR 2020. El 4 de diciembre de 2020, JATR lanzó un video para su sencillo "Blood on the Wind" donde Jack y The Ripper vienen. a la vida como avatares en un escenario lleno de imágenes destellantes e irreales entremezclando arte glitch, psicodelia estática y digital.
  </p>
  <p>
    El 20 de abril de 2021, el dúo lanzó dos versiones generadas con inteligencia artificial de Frank Sinatra y Ella Fitzgerald de "Homemade Lobotomy" uno de los sencillos de su segundo LP "Waking Dream". Desde el 29 de agosto hasta el 7 de septiembre de 2021, JATR estrenó "Cosmonaut", una nueva experiencia de realidad virtual en Burning Man Project Virtual Edition 2021.
  </p>
  <p>
    El 25 de octubre de 2021, JATR lanzó un nuevo videojuego en 3D titulado JATR-3D "Zombies from Outer Space".
  </p>
  <p>
    El 20 de enero de 2023, el dúo lanzó un nuevo video generado con inteligencia artificial para su sencillo titulado "Exile".
  </p>
  <p>
    Una conexión muy especial entre Jack and The Ripper se puede apreciar durante sus presentaciones en vivo, momentos catárticos y frenéticos cargados de solos de guitarra glitch, lasers y atmósferas electrónicas perfectamente orquestadas por el dúo.
  </p>
</div>

  );

  const bioContent = isEnglish ? englishBio : spanishBio;

  const toggleLanguage = () => setIsEnglish(!isEnglish);

  return (
    <div className="bio">
      <div className="language-toggle">
        
        <button className="language-button pressbutton" onClick={toggleLanguage}>
          ENG
        </button>
        <button className="language-button pressbutton" onClick={toggleLanguage}>
          ESP
        </button>

        <a
        href="https://www.tumblr.com/jatrpress"
        target="_blank"
        rel="noreferrer noopener"
        className="pressbutton"
      >
        PRESS
      </a>
      </div>
      <h2>Bio</h2>
      {bioContent}
      
    </div>
  );
};

export default Bio;














// import React, { useState } from 'react';
// import './Bio.css'; // Import your CSS file

// const Bio = () => {
//   const [isEnglish, setIsEnglish] = useState(true); // Default to English bio

//   const englishBio = (
//     <div>
//       <p>
//         ENG
//         {/* ... (English bio content) ... */}
//       </p>
//     </div>
//   );

//   const spanishBio = (
//     <div>
//       <p>
//         ESP
//         {/* ... (Spanish bio content) ... */}
//       </p>
//     </div>
//   );

//   const bioContent = isEnglish ? englishBio : spanishBio;

//   const toggleLanguage = () => setIsEnglish(!isEnglish);

//   return (
//     <div className="bio">
//       <h2>Bio</h2>
//       {bioContent}
//       <div className="language-toggle">
//         <button className="language-button pressbutton" onClick={toggleLanguage}>
//           ENG
//         </button>
//         <button className="language-button pressbutton" onClick={toggleLanguage}>
//           ESP
//         </button>
//       </div>
//       <a
//         href="https://www.tumblr.com/jatrpress"
//         target="_blank"
//         rel="noreferrer noopener"
//         className="pressbutton"
//       >
//         Press
//       </a>
//     </div>
//   );
// };

// export default Bio;













