import React from 'react';
import './Music.css'; 

const Music = () => {
  const albums = [
    {
      title: 'Waking Dream',
      coverImage: '/images/wakingdream.png',
      bandcampUrl: 'https://jackandtheripper.bandcamp.com/album/waking-dream',
      spotifyUrl: 'https://open.spotify.com/album/5AwMeqvF63dleuwEI0ssDE',
      appleMusicUrl: 'https://music.apple.com/us/album/waking-dream/1438160677',
    },
    {
      title: 'Vol. 2',
      coverImage: '/images/vol2.jpeg',
      bandcampUrl: 'https://jackandtheripper.bandcamp.com/album/jack-and-the-ripper-vol-2',
      spotifyUrl: 'https://open.spotify.com/album/4Y6Z83sU52ZGCRVUNuh4NP',
      appleMusicUrl: 'https://music.apple.com/us/album/jack-and-the-ripper-vol-2/936948844',
    },
  ];

  const handleAlbumClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="music-container">
      {albums.map((album) => (
        <div className="album" key={album.title}>
          <div className="album-info">
            <h2>{album.title}</h2>
            <div className="buttons">
              <a href={album.spotifyUrl} target="_blank" rel="noreferrer">
                <button>Spotify</button>
              </a>
              <a href={album.appleMusicUrl} target="_blank" rel="noreferrer">
                <button>Apple Music</button>
              </a>
            </div>
          </div>
          <img
            src={album.coverImage}
            alt={album.title + ' Album Cover'}
            onClick={() => handleAlbumClick(album.bandcampUrl)}
          />
        </div>
      ))}
    </div>
  );
};

export default Music;







// import React from 'react';

// const Music = () => {
//   const albums = [
//     {
//       title: 'Waking Dream',
//       coverImage: '/images/wakingdream.png', 
//       bandcampUrl: 'https://jackandtheripper.bandcamp.com/album/waking-dream',
//       spotifyUrl: 'https://open.spotify.com/album/5AwMeqvF63dleuwEI0ssDE', 
//       appleMusicUrl: 'https://music.apple.com/us/album/waking-dream/1438160677', 
//     },
//     {
//       title: 'Vol. 2',
//       coverImage: '/images/vol2.jpeg', 
//       bandcampUrl: 'https://jackandtheripper.bandcamp.com/album/jack-and-the-ripper-vol-2',
//       spotifyUrl: 'https://open.spotify.com/album/4Y6Z83sU52ZGCRVUNuh4NP', 
//       appleMusicUrl: 'https://music.apple.com/us/album/jack-and-the-ripper-vol-2/936948844', 
//     },
//   ];

//   const handleAlbumClick = (url) => {
//     window.location.href = url; 
//   };

//   return (
//     <div className="music-container">
//       {albums.map((album) => (
//         <div className="album" key={album.title}>
//           <img
//             src={album.coverImage}
//             alt={album.title + ' Album Cover'}
//             onClick={() => handleAlbumClick(album.bandcampUrl)}
//           />
//           <h2>{album.title}</h2>
//           <div className="buttons">
//             <a href={album.spotifyUrl} target="_blank" rel="noreferrer">
//               <button>Spotify</button>
//             </a>
//             <a href={album.appleMusicUrl} target="_blank" rel="noreferrer">
//               <button>Apple Music</button>
//             </a>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Music;
