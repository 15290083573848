 // Use your EmailJS service ID, template ID, and user ID (WORKING VERSION)

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; 

function Contact() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    emailjs.send(serviceId, templateId, { email }, publicKey)
      .then((response) => {
        console.log('Email sent successfully:', response);
        alert('Subscription successful!');
        setEmail(''); // Clear email input after successful submission
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('Failed to subscribe. Please try again later.');
      });
  };

  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>JACKANDTHERIPPER@GMAIL.COM</p>
      <form onSubmit={handleSubmit}>
        <input type="email" id="email" value={email} placeholder="Enter email address" onChange={handleEmailChange} style={{ textAlign: 'center' }}/>
        <button type="submit">Subscribe</button>
      </form>
      <div className="contact-links">
        <a href="https://twitter.com/jackandripper" target="_blank" rel="noreferrer">Twitter</a>
        <a href="https://www.youtube.com/jackandtheripper" target="_blank" rel="noreferrer">Youtube</a>
        {/* <a href="http://veer.tv/vr/Jackandtheripper" target="_blank" rel="noreferrer">VeeR VR</a> */}
        <a href="https://soundcloud.com/jackandtheripper" target="_blank" rel="noreferrer">Soundcloud</a>
        <a href="https://www.facebook.com/JackandTheRipper" target="_blank" rel="noreferrer">Facebook</a>
        <a href="https://jackandtheripper.bandcamp.com/track/exile" target="_blank" rel="noreferrer">Bandcamp</a>
        <a href="https://jackandtheripper.tumblr.com" target="_blank" rel="noreferrer">Tumblr</a>
        <a href="https://www.instagram.com/jackandtheripper" target="_blank" rel="noreferrer">Instagram</a>
        <a href="https://www.tiktok.com/@jackandtheripper" target="_blank" rel="noreferrer">TikTok</a>
        <a href="https://open.spotify.com/artist/4Vy87yLtntG7qGcssWqKKM" target="_blank" rel="noreferrer">Spotify</a>
      </div>
    </section>
  );
}

export default Contact;



















