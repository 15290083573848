import React, { useState, useEffect } from 'react';
import './Videos.css'; // Import the CSS file

const videoData = [
  { url: 'https://www.youtube.com/embed/-zvKpjHNCLI', title: 'Video 1' },
  { url: 'https://www.youtube.com/embed/fHqe3L8Q1Rc', title: 'Video 2' },
  { url: 'https://www.youtube.com/embed/uJ96QYORVJg', title: 'Video 3' },
  { url: 'https://www.youtube.com/embed/yee5zCeD9b0', title: 'Video 4' },
  { url: 'https://www.youtube.com/embed/dpyWFXCDxmI', title: 'Video 5' },
  { url: 'https://www.youtube.com/embed/n6BCpnjgb6g', title: 'Video 6' },
  { url: 'https://www.youtube.com/embed/VlLynEsC9wo', title: 'Video 7' },
  { url: 'https://www.youtube.com/embed/ZBL_64oWLSY', title: 'Video 8' },
  { url: 'https://www.youtube.com/embed/6bH3P7W4p8A', title: 'Video 9' },
  { url: 'https://www.youtube.com/embed/Ucg_hl79uWA', title: 'Video 10' },
  { url: 'https://www.youtube.com/embed/N1O56NAvdtw', title: 'Video 11' },
  { url: 'https://www.youtube.com/embed/66sxYcoVqNg', title: 'Video 12' },
  { url: 'https://www.youtube.com/embed/uGKDos0h3yY', title: 'Video 13' },
  { url: 'https://www.youtube.com/embed/StXNuhT4lnA', title: 'Video 14' },
  { url: 'https://www.youtube.com/embed/RbL9OygDy2E', title: 'Video 15' },
  { url: 'https://www.youtube.com/embed/BCVM_G72Vxs', title: 'Video 16' },
  { url: 'https://www.youtube.com/embed/rou4e7a_M5A', title: 'Video 17' },
  { url: 'https://www.youtube.com/embed/zLWAiXZVcGU', title: 'Video 18' },
];

const Videos = () => {
  const [playerWidth, setPlayerWidth] = useState('560px');
  const [playerHeight, setPlayerHeight] = useState('315px');

  useEffect(() => {
    const updatePlayerSize = () => {
      const viewportWidth = window.innerWidth;

      if (viewportWidth <= 768) {
        const dynamicWidth = viewportWidth * 0.9; // 90% of viewport width
        const dynamicHeight = dynamicWidth * (9 / 16); // Maintain 16:9 aspect ratio
        setPlayerWidth(`${dynamicWidth}px`);
        setPlayerHeight(`${dynamicHeight}px`);
      } else {
        setPlayerWidth('560px'); // Default size for larger screens
        setPlayerHeight('315px');
      }
    };

    updatePlayerSize(); // Set initial size
    window.addEventListener('resize', updatePlayerSize); // Listen for window resize

    return () => window.removeEventListener('resize', updatePlayerSize); // Cleanup on unmount
  }, []);

  return (
    <div className="videos-container">
      {videoData.map((video) => (
        <div key={video.url} className="video-item">
          <iframe
            width={playerWidth}
            height={playerHeight}
            src={video.url}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default Videos;





// import React from 'react';
// import './Videos.css'; // Import the CSS file

// const videoData = [
//   { url: 'https://www.youtube.com/embed/-zvKpjHNCLI', title: 'Video 1' },
//   { url: 'https://www.youtube.com/embed/fHqe3L8Q1Rc', title: 'Video 2' },
//   { url: 'https://www.youtube.com/embed/uJ96QYORVJg', title: 'Video 3' },
//   { url: 'https://www.youtube.com/embed/yee5zCeD9b0', title: 'Video 4' },
//   { url: 'https://www.youtube.com/embed/dpyWFXCDxmI', title: 'Video 5' },
//   { url: 'https://www.youtube.com/embed/n6BCpnjgb6g', title: 'Video 6' },
//   { url: 'https://www.youtube.com/embed/VlLynEsC9wo', title: 'Video 7' },
//   { url: 'https://www.youtube.com/embed/ZBL_64oWLSY', title: 'Video 8' },
//   { url: 'https://www.youtube.com/embed/6bH3P7W4p8A', title: 'Video 9' },
//   { url: 'https://www.youtube.com/embed/Ucg_hl79uWA', title: 'Video 10' },
//   { url: 'https://www.youtube.com/embed/N1O56NAvdtw', title: 'Video 11' },
//   { url: 'https://www.youtube.com/embed/66sxYcoVqNg', title: 'Video 12' },
//   { url: 'https://www.youtube.com/embed/uGKDos0h3yY', title: 'Video 13' },
//   { url: 'https://www.youtube.com/embed/StXNuhT4lnA', title: 'Video 14' },
//   { url: 'https://www.youtube.com/embed/RbL9OygDy2E', title: 'Video 15' },
//   { url: 'https://www.youtube.com/embed/BCVM_G72Vxs', title: 'Video 17' },
//   { url: 'https://www.youtube.com/embed/rou4e7a_M5A', title: 'Video 17' },
//   { url: 'https://www.youtube.com/embed/zLWAiXZVcGU', title: 'Video 18' },
// ];

// const Videos = () => {
//   return (
//     <div className="videos-container">
//       {videoData.map((video) => (
//         <div key={video.url} className="video-item">
//           <iframe
//             width="560"
//             height="315"
//             src={video.url}
//             title={video.title}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           ></iframe>
//           {/* <p>{video.title}</p> */}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Videos;







// https://youtu.be/-zvKpjHNCLI?si=p2DnDJqu_WBJIctQ



// https://youtu.be/fHqe3L8Q1Rc



// https://youtu.be/uJ96QYORVJg



// https://youtu.be/yee5zCeD9b0



// https://youtu.be/dpyWFXCDxmI



// https://youtu.be/n6BCpnjgb6g



// https://youtu.be/VlLynEsC9wo



// https://youtu.be/ZBL_64oWLSY



// https://youtu.be/6bH3P7W4p8A



// https://youtu.be/Ucg_hl79uWA



// https://youtu.be/N1O56NAvdtw



// https://youtu.be/66sxYcoVqNg



// https://youtu.be/uGKDos0h3yY



// https://youtu.be/StXNuhT4lnA



// https://youtu.be/RbL9OygDy2E



// https://youtu.be/BCVM_G72Vxs



// https://youtu.be/rou4e7a_M5A



// https://youtu.be/zLWAiXZVcGU










