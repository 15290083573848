// // jatr2d.js

// import React from 'react';
// import './Jatr2d.css';

// const Jatr2d = () => {
//   const handleButtonClick = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
//     <div className="jatr2d-container">
//       <img src="/images/jatr2dlogo.png" alt="JATR 2D Logo" className="jatr2d-logo" />
//       <p className="jatr2d-text">Play Jack and The Ripper's 2D GAME Music App Single</p>

//       <div className="jatr2d-sections">
//         {/* Jack Section */}
//         <div className="jatr2d-section jack">
//           <h2>Jack</h2>
//           <div className="jatr2d-buttons">
//             <div className="jatr2d-row">
//               <div>
//                 <img src="/images/bluebuttonjack1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-jack.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/bluebuttonjack1.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-jack.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//             <div className="jatr2d-row">
//               <p>Mobile</p>
//               <div>
//                 <img src="/images/bluebuttonjack1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-jack-mobile.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/bluebuttonjack1.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-jack-mobile.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Co-op Section */}
//         <div className="jatr2d-section coop">
//           <h2>Co-op</h2>
//           <div className="jatr2d-buttons">
//             <div className="jatr2d-row">
//               <div>
//                 <img src="/images/greenbuttoncoop.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/greenbuttoncoop.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//             <div className="jatr2d-row">
//               <p>Mobile</p>
//               <div>
//                 <img src="/images/greenbuttoncoop.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-mobile.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/greenbuttoncoop.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-mobile.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Ripper Section */}
//         <div className="jatr2d-section ripper">
//           <h2>Ripper</h2>
//           <div className="jatr2d-buttons">
//             <div className="jatr2d-row">
//               <div>
//                 <img src="/images/redbuttonripper1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-ripper.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/redbuttonripper1.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-ripper.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//             <div className="jatr2d-row">
//               <p>Mobile</p>
//               <div>
//                 <img src="/images/redbuttonripper1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-ripper-mobile.netlify.app/')} />
//                 <span>Level 1</span>
//               </div>
//               <div>
//                 <img src="/images/redbuttonripper1.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-ripper-mobile.netlify.app/')} />
//                 <span>Level 2</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Image section */}
//       <img src="/images/jatrpixels.png" alt="JATR Pixels" className="jatr-pixels" />
//     </div>
//   );
// };

// export default Jatr2d;














// jatr2d.js

import React from 'react';
import './Jatr2d.css';

const Jatr2d = () => {
  const handleButtonClick = (url) => {
    window.open(url, '_blank'); 
  };

  return (
    <div className="jatr2d-container">
      <img src="/images/jatr2dlogo.png" alt="JATR 2D Logo" className="jatr2d-logo" />
      <p className="jatr2d-text">Play Jack and The Ripper's 2D GAME Music App Single</p>
      
      <div className="jatr2d-sections">
        {/* Jack Section */}
        <div className="jatr2d-section jack">
          <h2>Jack</h2>
          <div className="jatr2d-buttons">
            <div className="jatr2d-row">
              <div>Desktop</div>
              <img src="/images/bluebuttonjackl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-jack.netlify.app/')} />
              <img src="/images/bluebuttonjackl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-jack.netlify.app/')} />
            </div>
            <div className="jatr2d-row">
              <p>Mobile</p>
              <img src="/images/bluebuttonjackl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-jack-mobile.netlify.app/')} />
              <img src="/images/bluebuttonjackl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-jack-mobile.netlify.app/')} />
            </div>
          </div>
        </div>

        {/* Co-op Section */}
        <div className="jatr2d-section coop">
          <h2>Co-op</h2>
          <div className="jatr2d-buttons">
            <div className="jatr2d-row">
              <div>Desktop</div>
              <img src="/images/greenbuttoncoopl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost.netlify.app/')} />
              <img src="/images/greenbuttoncoopl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless.netlify.app/')} />
            </div>
            <div className="jatr2d-row">
              <p>Mobile</p>
              <img src="/images/greenbuttoncoopl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-mobile.netlify.app/')} />
              <img src="/images/greenbuttoncoopl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-mobile.netlify.app/')} />
            </div>
          </div>
        </div>

        {/* Ripper Section */}
        <div className="jatr2d-section ripper">
          <h2>Ripper</h2>
          <div className="jatr2d-buttons">
            <div className="jatr2d-row">
              <div>Desktop</div>
              <img src="/images/redbuttonripperl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-ripper.netlify.app/')} />
              <img src="/images/redbuttonripperl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-ripper.netlify.app/')} />
            </div>
            <div className="jatr2d-row">
              <p>Mobile</p>
              <img src="/images/redbuttonripperl1.png" alt="Level 1" onClick={() => handleButtonClick('https://jatr-2dgame-cantforgiveaghost-ripper-mobile.netlify.app/')} />
              <img src="/images/redbuttonripperl2.png" alt="Level 2" onClick={() => handleButtonClick('https://jatr-2dgame-pointless-ripper-mobile.netlify.app/')} />
            </div>
          </div>
        </div>
      </div>

      {/* Image section */}
      <img src="/images/jatrpixels.png" alt="JATR Pixels" className="jatr-pixels" />
    </div>
  );
};

export default Jatr2d;













