import React from 'react';
import './Store.css';

const Store = () => {
  const tshirtData = [
    {
      image: "/images/classicblack.png",
      alt: "T-Shirt 1",
      name: "CLASSIC - BLACK | WHITE",
      image: "/images/classicwhite.png",
      alt: "T-Shirt 2",
      // name: "CLASSIC - WHITE",
    },

    {
      image: "/images/giantinitialsblack.png",
      alt: "T-Shirt 3",
      name: "GIANT INITIALS - BLACK | WHITE",
      image: "/images/giantinitialswhite.png",
      alt: "T-Shirt 4",
      // name: "GIANT INITIALS - WHITE",
    },

    {
      image: "/images/chestlogoblack.png",
      alt: "T-Shirt 5",
      name: "CHEST LOGO - BLACK | WHITE",
      image: "/images/chestlogowhite.png",
      alt: "T-Shirt 6",
      // name: "CHEST LOGO - WHITE",
    },

  ];

  return (
    <div className="store-container">
      <div className="order-text">PLACE YOUR ORDER NOW - JACKANDTHERIPPER@GMAIL.COM</div>
      <div className="tshirt-rows">
        {tshirtData.map((tshirt) => (
          <div className="tshirt-row" key={tshirt.name}>  {/* Add a unique key for each row */}
            <img className="tshirt-image" src={tshirt.image} alt={tshirt.alt} />
            <div className="tshirt-name">{tshirt.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Store;









// import React from 'react';

// const Store = () => {
//   return (
//     <div className="store-container">
//       <div className="order-text">PLACE YOUR ORDER NOW - JACKANDTHERIPPER@GMAIL.COM</div>
//       <div className="tshirt-rows">
//         <div className="tshirt-row">
//           <img className="tshirt-image" src="/images/classicblack.png" alt="T-Shirt 1" />
//           <img className="tshirt-image" src="/images/classicwhite.png" alt="T-Shirt 2" />
//           <div className="tshirt-name">CLASSIC - BLACK</div>
//           <div className="tshirt-name">TCLASSIC - WHITE</div>
//         </div>
//         <div className="tshirt-row">
//           <img className="tshirt-image" src="/images/giantinitialsblack.png" alt="T-Shirt 3" />
//           <img className="tshirt-image" src="/images/giantinitialswhite.png" alt="T-Shirt 4" />
//           <div className="tshirt-name">GIANT INITIALS - BLACK</div>
//           <div className="tshirt-name">GIANT INITIALS - WHITE</div>
//         </div>
//         <div className="tshirt-row">
//           <img className="tshirt-image" src="/images/chestlogoblack.png" alt="T-Shirt 5" />
//           <img className="tshirt-image" src="/images/chestlogowhite.png" alt="T-Shirt 6" />
//           <div className="tshirt-name">CHEST LOGO - BLACK</div>
//           <div className="tshirt-name">CHEST LOGO - WHITE</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Store;
